import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

export default function createApolloClient() {
  let uri = process.env.API_PUBLIC_URL.endsWith('/')
    ? `${process.env.API_PUBLIC_URL}graphql`
    : `${process.env.API_PUBLIC_URL}/graphql`;

  if (typeof window !== 'undefined') {
    console.log(`apollo client: ${uri}`);
  } else {
    uri = `${process.env.API_URL}/graphql`;
  }

  const link = new HttpLink({
    // credentials: 'include',
    uri,
  });

  const cache = new InMemoryCache();

  const client = new ApolloClient({
    link,
    cache,
    ssrMode: typeof window === 'undefined',
  });

  return client;
}
